@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  body {
    @apply text-gray-900 bg-gray-50;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center rounded-lg px-4 py-2 text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-primary {
    @apply bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500;
  }

  .btn-secondary {
    @apply bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring-primary-500;
  }

  .input {
    @apply block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm;
  }
}

/* Thêm vào src/index.css sau phần @tailwind */

/* Custom styles for React Select */
.select__control {
  @apply border border-gray-300 !bg-white rounded-lg shadow-sm min-h-[38px] !cursor-pointer;
}

.select__control:hover {
  @apply border-primary-500;
}

.select__control--is-focused {
  @apply border-primary-500 ring-1 ring-primary-500 !shadow-none;
}

.select__control--is-disabled {
  @apply !bg-gray-50 cursor-not-allowed;
}

.select__value-container {
  @apply py-0.5 px-3;
}

.select__placeholder {
  @apply text-gray-500 text-sm;
}

.select__input-container {
  @apply text-sm text-gray-900 m-0 p-0;
}

.select__menu {
  @apply mt-1 !rounded-lg border border-gray-300 shadow-lg bg-white z-50;
}

.select__menu-list {
  @apply p-1;
}

.select__option {
  @apply text-sm text-gray-900 rounded-md mx-1 cursor-pointer;
}

.select__option--is-focused {
  @apply !bg-primary-50;
}

.select__option--is-selected {
  @apply !bg-primary-100 text-primary-900;
}

.select__multi-value {
  @apply bg-primary-50 rounded-md m-0.5;
}

.select__multi-value__label {
  @apply text-sm text-primary-700 py-0.5;
}

.select__multi-value__remove {
  @apply text-primary-700 hover:text-red-500 hover:bg-transparent rounded-md cursor-pointer;
}

/* Custom scrollbar for the select dropdown */
.select__menu-list::-webkit-scrollbar {
  @apply w-2;
}

.select__menu-list::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.select__menu-list::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded-full;
}

.select__menu-list::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

/* Form specific styles */
.form-tooltip {
  @apply absolute left-6 -top-2 z-50 w-48 bg-gray-900 text-white text-xs rounded py-1 px-2;
}

.image-preview-grid {
  @apply mt-4 grid grid-cols-4 gap-4;
}

.image-preview-item {
  @apply relative w-24 h-24 rounded overflow-hidden;
}

.image-preview-image {
  @apply w-full h-full object-cover;
}

.image-delete-button {
  @apply absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors;
}

.form-section {
  @apply space-y-4;
}

.form-group {
  @apply relative space-y-1;
}

.form-label {
  @apply block text-sm font-medium text-gray-700;
}

.form-input {
  @apply block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm;
}

.form-error {
  @apply text-sm text-red-500 mt-1;
}

.form-help-icon {
  @apply text-gray-400 cursor-help ml-1 inline-block;
}

/* Checkbox styles */
.checkbox-group {
  @apply flex items-center gap-4;
}

.checkbox-label {
  @apply flex items-center gap-2 cursor-pointer;
}

.checkbox-input {
  @apply rounded text-primary-600 focus:ring-primary-500 cursor-pointer;
}

/* Image upload area */
.upload-area {
  @apply mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-primary-500 transition-colors cursor-pointer;
}

.upload-icon {
  @apply mx-auto h-12 w-12 text-gray-400;
}

.upload-text {
  @apply flex text-sm text-gray-600;
}

.upload-button {
  @apply relative cursor-pointer bg-white font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500;
}

.upload-input {
  @apply sr-only;
}